import { Environment } from "../environment.type";

export const environment: Environment = {
  production: true,
  environmentName: 'FSV - QA',
  environmentId: 'fsv',
  container: 'https://foodservicevend.blob.core.windows.net',
  endPoint: 'https://fsv-backend-qa.azurewebsites.net',
  graphqlEndPoint: 'https://fsv-backend-qa.azurewebsites.net/graphql',
  frontEnd: 'https://fsv-frontend-qa.azurewebsites.net/',
  analytics: 'UA-172843824-1 - GTM-K64QLZV',
  adminUsername: 'frito',
  adminPassword: 'lay',
};
